import React from "react";
import ContactOne from "@/components/contact-one";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";
import PageHeader from "@/components/page-header"

const Contact = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Get a Quote">
        <HeaderOne />
          <PageHeader title="Get a Quote" name="Quote" />
        <ContactOne />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Contact;
