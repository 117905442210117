import React from "react";
import SectionTitle from "@/components/section-title";
import {ContactInfosData} from "@/data";
import {Col, Row} from "react-bootstrap";
import {ContactFromData} from "../data";


const ContactOne = () => {
  return (
    <section className="contact-section sec-pad">
      <div className="thm-container">
        <div className="sec-title ">
          <p>
            Thank you for choosing Newby Enterprises! If you’re ready to submit your quote request, use the form below to let us know what you’re looking to order. If you have any questions about our offerings, don’t hesitate to reach out.
            <br/><br/>
            When submitting your request, be sure to include the following:
            <ul>
              <li> Type of material and thickness</li>
              <li> Face colour</li>
              <li> Square or round corners and the size of corners</li>
              <li> If there are holes, size of holes, and location of holes</li>
              <li> Quantity</li>
            </ul>
            <br/>
            NOTE: The below form is best used on desktop. If you’re on your mobile, turn your phone horizontally or zoom out on each section as you go.
          </p>

        </div>
        <Row>
          <Col lg={12}>
            <div className="contact-form-content">
              <SectionTitle data={ContactFromData.sectionContent} />
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="quote" className="contact-form">
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="quote" />
                <table>
                  <tr>
                    <td>Purchaser</td>
                    <td><input type="text" name="purchaser"  maxLength="100" /></td>
                  </tr>
                  <tr>
                    <td>Customer</td>
                    <td><input type="text" name="customer"  maxLength="100" /></td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td ><input type="text" name="email_address"  maxLength="100" /></td>
                    <td/>
                    <td/>
                  </tr>
                  <tr>
                    <td>Notes</td>
                    <td><textarea rows="3" cols="50" name="comments">
            </textarea></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><h3>Signs</h3></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </table>
                <table width="840" border="1" cellPadding="5">
                  <tr >
                    <td align="center" valign="middle">Item</td>
                    <td align="center" valign="middle"> Quantity </td>
                    <td align="center" valign="middle">Width</td>
                    <td align="center" valign="middle">Height</td>
                    <td width="50" align="center" valign="middle">UOM</td>
                    <td align="center" valign="middle">Gauge</td>
                    <td align="center" valign="middle">Finish</td>
                    <td align="center" valign="middle">Color</td>
                    <td align="center" valign="middle">Radius</td>
                    <td align="center" valign="middle"># of Holes</td>
                    <td align="center" valign="middle">Hole Size</td>
                    <td align="center" valign="middle">Centres</td>
                  </tr>
                  <tr>
                    <td align="center" valign="middle">A</td>
                    <td align="center" valign="middle"><input type="text" name="Item_A_QTY"   size="2"  /></td>
                    <td align="center" valign="middle"><input name="Item_A_W" type="text" id="Item_A_W" size="2" /></td>
                    <td align="center" valign="middle"><input name="Item_A_H" type="text" id="Item_A_H" size="2" /></td>
                    <td align="center" valign="middle"><label>
                      <input type="radio" name="Item_A_UOM" value="CM" id="Item_A_UOM_CM" />
                      CM</label>
                      <br />
                      <label>
                        <input type="radio" name="Item_A_UOM" value="Inch" id="Item_A_UOM_IN" />
                        Inch</label></td>
                    <td align="center" valign="middle"><label id="Item_A_Gauge"/>
                      <select name="Item_A_Gauge" id="Item_A_Gauge">
                        <option>...select</option>
                        <option>.032&quot;</option>
                        <option>.040&quot;</option>
                        <option>.051&quot;</option>
                        <option>.063&quot;</option>
                        <option>.080&quot;</option>
                      </select></td>
                    <td align="center" valign="middle"><label id="Item_A_Finish"/>
                      <select name="Item_A_Finish" id="Item_A_Finish">
                        <option selected="selected">...select</option>
                        <option>Baked Enamel</option>
                        <option>Degreased</option>
                        <option>Vinyl</option>
                        <option>3M Reflective</option>
                        <option>High Intensity</option>
                        <option>Diamond Grade</option>
                      </select></td>
                    <td align="center" valign="middle"><select name="Item_A_Color" id="Item_A_Color">
                      <option selected="selected">...select</option>
                      <option>none</option>
                      <option>White</option>
                      <option>Yellow</option>
                      <option>Orange</option>
                      <option>Blue</option>
                      <option>Green</option>
                      <option>White 4090</option>
                      <option>Yellow 4091</option>
                      <option>
                        Flor Orange</option>
                      <option>Flor Yellow-Green</option>
                      <option>Custom</option>
                    </select></td>
                    <td align="center" valign="middle"><label id="Item_A_Radius"/>
                      <select name="Item_A_Radius" id="Item_A_Radius">
                        <option>...select</option>
                        <option>SQ</option>
                        <option>1/4&quot;</option>
                        <option>5/16&quot;</option>
                        <option>1/2&quot;</option>
                        <option>3/4&quot;</option>
                        <option>1-1/2&quot;</option>
                      </select></td>
                    <td align="center" valign="middle"><label id="Item_A_Holes"/>
                      <input name="Item_A_Holes" type="text" id="Item_A_Holes" size="1" /></td>
                    <td align="center" valign="middle"><label id="Item_A_Hole_Size"/>
                      <select name="Item_A_Hole_Size" id="Item_A_Hole_Size">
                        <option>...select</option>
                        <option>none</option>
                        <option>1/8&quot;</option>
                        <option>3/16&quot;</option>
                        <option>1/4&quot;</option>
                        <option>5/16&quot;</option>
                        <option>3/8&quot;</option>
                        <option>7/16&quot;</option>
                      </select></td>
                    <td align="center" valign="middle"><label id="Item_A_Centres"/>
                      <input name="Item_A_Centres" type="text" id="Item_A_Centres" size="1" /></td>
                  </tr>
                  <tr>
                    <td align="center" valign="middle">B</td>
                    <td align="center" valign="middle"><input name="Item_B_QTY" type="text" id="Item_B_QTY" size="2"  /></td>
                    <td align="center" valign="middle"><input name="Item_B_W" type="text" id="Item_B_W" size="2"  /></td>
                    <td align="center" valign="middle"><input name="Item_B_H" type="text" id="Item_B_H" size="2"  /></td>
                    <td width="50" align="center" valign="middle"><label>
                      <input type="radio" name="Item_B_UOM" value="CM" id="Item_B_UOM_CM" />
                      CM</label>
                      <br />
                      <label>
                        <input type="radio" name="Item_B_UOM" value="Inch" id="Item_B_UOM_IN" />
                        Inch</label></td>
                    <td align="center" valign="middle"><label id="Item_B_Gauge"/>
                      <select name="Item_B_Gauge" id="Item_B_Gauge">
                        <option>...select</option>
                        <option>.032&quot;</option>
                        <option>.040&quot;</option>
                        <option>.051&quot;</option>
                        <option>.063&quot;</option>
                        <option>.080&quot;</option>
                      </select></td>
                    <td align="center" valign="middle">
                      <select name="Item_B_Finish" id="Item_B_Finish">
                      <option selected="selected">...select</option>
                      <option>Baked Enamel</option>
                      <option>Degreased</option>
                      <option>Vinyl</option>
                      <option>3M Reflective</option>
                      <option>High Intensity</option>
                      <option>Diamond Grade</option>
                    </select></td>
                    <td align="center" valign="middle"><select name="Item_B_Color" id="Item_B_Color">
                      <option selected="selected">...select</option>
                      <option>none</option>
                      <option>White</option>
                      <option>Yellow</option>
                      <option>Orange</option>
                      <option>Blue</option>
                      <option>Green</option>
                      <option>White 4090</option>
                      <option>Yellow 4091</option>
                      <option> Flor Orange</option>
                      <option>Flor Yellow-Green</option>
                      <option>Custom</option>
                    </select></td>
                    <td align="center" valign="middle"><select name="Item_B_Radius" id="Item_B_Radius">
                      <option>...select</option>
                      <option>SQ</option>
                      <option>1/4&quot;</option>
                      <option>5/16&quot;</option>
                      <option>1/2&quot;</option>
                      <option>3/4&quot;</option>
                      <option>1-1/2&quot;</option>
                    </select></td>
                    <td align="center" valign="middle"><input name="Item_B_Holes" type="text" id="Item_B_Holes" size="1"  /></td>
                    <td align="center" valign="middle"><select name="Item_B_Hole_Size" id="Item_B_Hole_Size">
                      <option>...select</option>
                      <option>none</option>
                      <option>1/8&quot;</option>
                      <option>3/16&quot;</option>
                      <option>1/4&quot;</option>
                      <option>5/16&quot;</option>
                      <option>3/8&quot;</option>
                      <option>7/16&quot;</option>
                    </select></td>
                    <td align="center" valign="middle"><input name="Item_B_Centres" type="text" id="Item_B_Centres" size="1" /></td>
                  </tr>
                  <tr>
                    <td align="center" valign="middle">C</td>
                    <td align="center" valign="middle"><input name="Item_C_QTY" type="text" id="Item_C_QTY" size="1" /></td>
                    <td align="center" valign="middle"><input name="Item_C_W" type="text" id="Item_C_W" size="1" /></td>
                    <td align="center" valign="middle"><input name="Item_C_H" type="text" id="Item_C_H" size="1" /></td>
                    <td width="50" align="center" valign="middle"><label>
                      <input type="radio" name="Item_C_UOM" value="CM" id="Item_C_UOM_CM" />
                      CM</label>
                      <br />
                      <label>
                        <input type="radio" name="Item_C_UOM" value="Inch" id="Item_C_UOM_IN" />
                        Inch</label></td>
                    <td align="center" valign="middle"><label id="Item_C_Gauge"/>
                      <select name="Item_C_Gauge" id="Item_C_Gauge">
                        <option>...select</option>
                        <option>.032&quot;</option>
                        <option>.040&quot;</option>
                        <option>.051&quot;</option>
                        <option>.063&quot;</option>
                        <option>.080&quot;</option>
                      </select></td>
                    <td align="center" valign="middle"><select name="Item_C_Finish" id="Item_C_Finish">
                      <option selected="selected">...select</option>
                      <option>Baked Enamel</option>
                      <option>Degreased</option>
                      <option>Vinyl</option>
                      <option>3M Reflective</option>
                      <option>High Intensity</option>
                      <option>Diamond Grade</option>
                    </select></td>
                    <td align="center" valign="middle"><select name="Item_C_Color" id="Item_C_Color">
                      <option selected="selected">...select</option>
                      <option>none</option>
                      <option>White</option>
                      <option>Yellow</option>
                      <option>Orange</option>
                      <option>Blue</option>
                      <option>Green</option>
                      <option>White 4090</option>
                      <option>Yellow 4091</option>
                      <option> Flor Orange</option>
                      <option>Flor Yellow-Green</option>
                      <option>Custom</option>
                    </select></td>
                    <td align="center" valign="middle"><select name="Item_C_Radius" id="Item_C_Radius">
                      <option>...select</option>
                      <option>SQ</option>
                      <option>1/4&quot;</option>
                      <option>5/16&quot;</option>
                      <option>1/2&quot;</option>
                      <option>3/4&quot;</option>
                      <option>1-1/2&quot;</option>
                    </select></td>
                    <td align="center" valign="middle"><input name="Item_C_Holes" type="text" id="Item_C_Holes" size="1" /></td>
                    <td align="center" valign="middle"><select name="Item_C_Hole_Size" id="Item_C_Hole_Size">
                      <option>...select</option>
                      <option>none</option>
                      <option>1/8&quot;</option>
                      <option>3/16&quot;</option>
                      <option>1/4&quot;</option>
                      <option>5/16&quot;</option>
                      <option>3/8&quot;</option>
                      <option>7/16&quot;</option>
                    </select></td>
                    <td align="center" valign="middle"><input name="Item_C_Centres" type="text" id="Item_C_Centres" size="1" /></td>
                  </tr>
                  <tr>
                    <td align="center" valign="middle">D</td>
                    <td align="center" valign="middle"><input name="Item_D_QTY" type="text" id="Item_D_QTY" size="1" /></td>
                    <td align="center" valign="middle"><input name="Item_D_W" type="text" id="Item_D_W" size="1" /></td>
                    <td align="center" valign="middle"><input name="Item_D_H" type="text" id="Item_D_H" size="1" /></td>
                    <td width="50" align="center" valign="middle"><label>
                      <input type="radio" name="Item_D_UOM" value="CM" id="Item_D_UOM_CM" />
                      CM</label>
                      <br />
                      <label>
                        <input type="radio" name="Item_D_UOM" value="Inch" id="Item_D_UOM_IN" />
                        Inch</label></td>
                    <td align="center" valign="middle"><label  id="Item_D_Gauge"/>
                      <select name="Item_D_Gauge" id="Item_D_Gauge">
                        <option>...select</option>
                        <option>.032&quot;</option>
                        <option>.040&quot;</option>
                        <option>.051&quot;</option>
                        <option>.063&quot;</option>
                        <option>.080&quot;</option>
                      </select></td>
                    <td align="center" valign="middle"><select name="Item_D_Finish" id="Item_D_Finish">
                      <option selected="selected">...select</option>
                      <option>Baked Enamel</option>
                      <option>Degreased</option>
                      <option>Vinyl</option>
                      <option>3M Reflective</option>
                      <option>High Intensity</option>
                      <option>Diamond Grade</option>
                    </select></td>
                    <td align="center" valign="middle"><select name="Item_D_Color" id="Item_D_Color">
                      <option selected="selected">...select</option>
                      <option>none</option>
                      <option>White</option>
                      <option>Yellow</option>
                      <option>Orange</option>
                      <option>Blue</option>
                      <option>Green</option>
                      <option>White 4090</option>
                      <option>Yellow 4091</option>
                      <option> Flor Orange</option>
                      <option>Flor Yellow-Green</option>
                      <option>Custom</option>
                    </select></td>
                    <td align="center" valign="middle"><select name="Item_D_Radius" id="Item_D_Radius">
                      <option>...select</option>
                      <option>SQ</option>
                      <option>1/4&quot;</option>
                      <option>5/16&quot;</option>
                      <option>1/2&quot;</option>
                      <option>3/4&quot;</option>
                      <option>1-1/2&quot;</option>
                    </select></td>
                    <td align="center" valign="middle"><input name="Item_D_Holes" type="text" id="Item_D_Holes" size="1"/></td>
                    <td align="center" valign="middle"><select name="Item_D_Hole_Size" id="Item_D_Hole_Size">
                      <option>...select</option>
                      <option>none</option>
                      <option>1/8&quot;</option>
                      <option>3/16&quot;</option>
                      <option>1/4&quot;</option>
                      <option>5/16&quot;</option>
                      <option>3/8&quot;</option>
                      <option>7/16&quot;</option>
                    </select></td>
                    <td align="center" valign="middle"><input name="Item_D_Centres" type="text" id="Item_D_Centres" size="1" /></td>
                  </tr>
                </table>
                <table width="100%" border="0" cellPadding="0">
                  <tr>
                    <td width="47%" align="left"><h3><br />
                      Posts </h3>
                      <table width="86%" border="1" cellPadding="2">
                        <tr>
                          <td colSpan="2" align="left"><strong>Heavy Duty</strong></td>
                          <td rowSpan="5" align="center">&nbsp;</td>
                          <td colSpan="2" align="left"><strong>Light Duty</strong></td>
                          <td rowSpan="5" align="center">&nbsp;</td>
                          <td colSpan="2" align="left"><strong>Quads</strong></td>
                        </tr>
                        <tr>
                          <td align="center">Item</td>
                          <td align="center">Quantity</td>
                          <td align="center">Item</td>
                          <td align="center">Quantity</td>
                          <td align="center">Item</td>
                          <td align="center">Quantity</td>
                        </tr>
                        <tr>
                          <td width="15%" align="center">8'</td>
                          <td width="15%" align="center"><input name="Item_HD_8" type="text" id="Item_HD_8" size="1" /></td>
                          <td width="15%" align="center">72&quot;</td>
                          <td width="15%" align="center"><input name="Item_LD_72" type="text" id="Item_LD_72" size="1" /></td>
                          <td width="15%" align="center">48&quot;</td>
                          <td width="15%" align="center"><input name="Item_Quad_48" type="text" id="Item_Quad_48" size="1" /></td>
                        </tr>
                        <tr>
                          <td width="15%" align="center">10'</td>
                          <td width="15%" align="center"><input name="Item_HD_10" type="text" id="Item_HD_10" size="1" /></td>
                          <td width="15%" align="center">96&quot;</td>
                          <td width="15%" align="center"><input name="Item_LD_96" type="text" id="Item_LD_96" size="1" /></td>
                          <td width="15%" align="center">&nbsp;</td>
                          <td width="15%" align="center">&nbsp;</td>
                        </tr>
                        <tr>
                          <td width="15%" align="center">12'</td>
                          <td width="15%" align="center"><input name="Item_HD_12" type="text" id="Item_HD_12" size="1" /></td>
                          <td width="15%" align="center">&nbsp;</td>
                          <td width="15%" align="center">&nbsp;</td>
                          <td width="15%" align="center">&nbsp;</td>
                          <td width="15%" align="center">&nbsp;</td>
                        </tr>
                      </table></td>
                    <td width="53%" align="center" valign="top"><p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p></td>
                  </tr>
                  <tr>
                    <td colSpan="4" align="left">
                      <br />     <button type="submit" className="thm-btn yellow-bg"> Submit </button>
                    </td>
                  </tr>
                </table>
              </form>
            </div>
          </Col>
        </Row>
        <p>
          If you are uncertain about any of the fields on the form above, here is some more information:
          <ul>
            <li>
              QTY: the quantity you’d like to order
            </li>
            <li>W: the width (if you are ordering different sizes, enter these as separate items)
            </li>
            <li>H: the height </li>
            <li>UOM: the Unit of Measurement (either Inches or CMs)
            </li>
            <li>Gauge: The gauge (this can be found under the product’s description)
            </li>
            <li>Finish: the type of finish you would like </li>
            <li>Colour: which colour you would like (if applicable)
            </li>
            <li>Radius: the radius (if applicable)
            </li>
            <li># of holes: the number of holes (if applicable)
            </li>
            <li>Hole Size: the size of holes you would like (if applicable)
            </li>
            <li>Centres: This is the measurement from the center of one hole to the next
            </li>
          </ul>
          Thank you, again! We look forward to working with you.
        </p>
      </div>
    </section>
  );
};

export default ContactOne;
